@font-face {
  font-family: "Verlag-font";
  src: url("../public/verlag_font/Verlag-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: justify;
}

::-webkit-scrollbar {
  scrollbar-color: rgb(0, 217, 255) !important;
  height: 1rem;
  width: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.walr-back {
  background-image: linear-gradient(90deg, #8d80ec, #6891de, #1db4c0);
}

.residex-back {
  background-image: linear-gradient(90deg, #198754, #9eecc8, #41c3b2);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.para-style2 {
  text-align: justify;
  word-wrap: break-word;
}

.bg-style3 {
  background-position: center;
  background-size: cover;
}

.bg-style4 {
  background-position: center;
}

.after-header {
  position: relative;
  padding-top: 5rem;
}

.margin-style {
  margin-left: 8rem;
}

.font-size1 {
  font-size: 0.8rem;
}

.box-shadow-style {
  box-shadow: 0px 1px 14px 0 #0000007a;
}

@keyframes popUpAnimation {
  from {
    opacity: 0;
    /* transform: translateY(-5px); */
    scale: 70%;
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
    scale: 100%;
  }
}

.slide-r-animation {
  animation: sliderAnimation 0.7s ease-out;
}


@keyframes sliderAnimation {
  from {
    transform: translateX(1800px);
  }
  to {
    transform: translateX(0);
  }
}

.slide-l-animation {
  animation: slidelAnimation 0.7s ease-out;
}

@keyframes slidelAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1800px);
  }
}

.pop-up-animation {
  animation: popUpAnimation 0.4s ease-out;
}

@keyframes slideUpAnimation {
  from {
    opacity: 0;
    transform: translateY(40px);
    z-index: -20000;
    scale: 0.8;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    z-index: -200000;
    scale: 1;
  }
}

.slide-up-animation {
  animation: slideUpAnimation 0.3s ease-in-out;
}

.alert-animation {
  animation: alertAnimation 0.5s ease-in-out;
}



@keyframes alertAnimation {
  from {
    opacity: 0;
    transform: translateY(-50px);
    scale: 100%;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    scale: 100%;
  }
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 1200px) {
  .after-header {
    position: relative;
    padding-top: 4.6rem;
  }
}
/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .after-header {
    position: relative;
    padding-top: 4.6rem;
  }
  .slide-l-animation {
    animation: slidelAnimation 3s ease-out;
  }

  .slide-r-animation {
    animation: sliderAnimation 1.5s ease-in;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 991px) {
  .after-header {
    position: relative;
    padding-top: 4.6rem;
  }
}

.numberAnimation {
  animation: numberAnime;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-duration: 0.8s;
}

@keyframes numberAnime {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.remove-white-bg {
  mix-blend-mode: multiply;
}
